import authenticationRoutes from 'containers/AccountModule/AccountRoutes';
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes';
// import DefectManagementRoutes from 'containers/ContractModule/DefectManagementModule/DefectManagementRoutes';
import ContractRoutes from 'containers/ContractModule/ContractRoutes';
import AccountingRoutes from 'containers/AccountingModule/AccountingRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
];

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
];

//error route always at the bottom//
const routes = [
  // ...homeRoutes,
  ...ContractRoutes,
  ...generalSettingRoutes,
  ...authenticationRoutes,
  ...AccountingRoutes,
  // ...DefectManagementRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
