import React, { lazy } from 'react';

const ContractListing = lazy(() =>
  import('./ContractListing').then(module => ({
    default: module.ContractListing,
  })),
);

const ContractForm = lazy(() =>
  import('./ContractForm').then(module => ({
    default: module.ContractForm,
  })),
);

const ContractDetail = lazy(() =>
  import('./ContractSubcontractDetail').then(module => ({
    default: module.ContractDetail,
  })),
);

const ContractSubMenu = lazy(() =>
  import('./ContractSubMenu').then(module => ({
    default: module.ContractSubMenu,
  })),
);

const ProjectWBSAndBudgeting = lazy(() =>
  import('./ProjectWBSAndBudgeting/ProjectWBSAndBudgeting').then(module => ({
    default: module.ProjectWBSAndBudgeting,
  })),
);

const ProjectWBSAndBudgetingValidation = lazy(() =>
  import('./ProjectWBSAndBudgeting/ProjectWBSAndBudgetingValidation').then(
    module => ({
      default: module.ProjectWBSAndBudgetingValidation,
    }),
  ),
);

// const ProjectWBSAndBudgetingAllocation = lazy(() =>
//   import('./ProjectWBSAndBudgetingAllocation/index').then(module => ({
//     default: module.ProjectWBSAndBudgetingAllocation,
//   })),
// );

const WBSOriginalBudgetTree = lazy(() =>
  import('./ProjectWBSAndBudgeting/components/CopyWBSTreeForm').then(
    module => ({
      default: module.CopyWBSTreeForm,
    }),
  ),
);

const UtilizedBudget = lazy(() =>
  import('./UtilizedBudget/index').then(module => ({
    default: module.UtilizedBudget,
  })),
);

const UtilizedBudgetForm = lazy(() =>
  import('./UtilizedBudgetForm/UtilizedBudgetForm').then(module => ({
    default: module.UtilizedBudgetForm,
  })),
);

const WBSBudgetRevisionListing = lazy(() =>
  import('./WBSBudgetRevision/WBSBudgetRevision').then(module => ({
    default: module.WBSBudgetRevision,
  })),
);

const WBSBudgetRevisionForm = lazy(() =>
  import('./WBSBudgetRevisionForm/WBSBudgetRevisionForm').then(module => ({
    default: module.WBSBudgetRevisionForm,
  })),
);

const BudgetTransferListing = lazy(() =>
  import('./BudgetTransfer/BudgetTransfer').then(module => ({
    default: module.BudgetTransfer,
  })),
);

const BudgetTransferForm = lazy(() =>
  import('./BudgetTransferForm/BudgetTransferForm').then(module => ({
    default: module.BudgetTransferForm,
  })),
);

const BudgetTransferDetails = lazy(() =>
  import('./BudgetTransferDetails/index').then(module => ({
    default: module.BudgetTransferDetails,
  })),
);

//GuaranteeInsuranceListing
const GuaranteeInsuranceListing = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceListing').then(module => ({
    default: module.GuaranteeInsuranceListing,
  })),
);

const GuaranteeInsuranceForm = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceForm').then(module => ({
    default: module.GuaranteeInsuranceForm,
  })),
);

const GuaranteeInsuranceDetail = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceDetail').then(module => ({
    default: module.GuaranteeInsuranceDetail,
  })),
);

const ProgressClaimListing = lazy(() =>
  import('./ProgressClaimModule/ProgressClaimListing').then(module => ({
    default: module.ProgressClaimListing,
  })),
);

const ProgressClaimDetail = lazy(() =>
  import('./ProgressClaimModule/ProgressClaimDetail').then(module => ({
    default: module.ProgressClaimDetail,
  })),
);

const VOListing = lazy(() =>
  import('./VO/VOListing').then(module => ({
    default: module.VOListing,
  })),
);

const VOForm = lazy(() =>
  import('./VO/VOForm').then(module => ({
    default: module.VOForm,
  })),
);

const VODetail = lazy(() =>
  import('./VO/VODetail').then(module => ({
    default: module.VODetail,
  })),
);

const VOProjectHealth = lazy(() =>
  import('./VO/VOProjectHealth').then(module => ({
    default: module.VOProjectHealth,
  })),
);

// const SiteProgressListing = lazy(() =>
//   import('./SiteProgress/SiteProgressListing').then(module => ({
//     default: module.SiteProgressListing,
//   })),
// );

// const SiteProgressForm = lazy(() =>
//   import('./SiteProgress/SiteProgressForm').then(module => ({
//     default: module.SiteProgressForm,
//   })),
// );

const ContractRoutes = [
  {
    props: { exact: true, path: '/home' },
    component: <ContractListing />,
  },
  {
    props: { exact: true, path: '/' },
    component: <ContractListing />,
  },
  {
    props: { exact: true, path: '/contract/add' },
    component: <ContractForm mode="add" consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/detail`,
    },
    component: <ContractDetail consubcon="contract" />,
  },
  {
    props: { exact: true, path: '/contract/submenu/:ID' },
    component: <ContractSubMenu />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/progress-claim',
    },
    component: <ProgressClaimListing />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/progress-claim/ClaimListDummy',
    },
    component: <ProgressClaimDetail />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo',
    },
    component: <VOListing consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/wbs-budgeting/:budgetID',
    },
    component: <ProjectWBSAndBudgeting consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/wbs-budgeting/:budgetID/validation',
    },
    component: <ProjectWBSAndBudgetingValidation consubcon="contract" />,
  },

  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/contract/submenu/:ID/wbs-budgeting/:budgetID/allocation/:wbsID',
  //   },
  //   component: <ProjectWBSAndBudgetingAllocation consubcon="contract" />,
  // },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/wbs-budgeting/budget-form',
    },
    component: <WBSOriginalBudgetTree consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/budget-revision',
    },
    component: <WBSBudgetRevisionListing consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/budget-revision/:mode',
    },
    component: <WBSBudgetRevisionForm consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/budget-revision/:budgetID/:mode',
    },
    component: <WBSBudgetRevisionForm consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/utilized-budget',
    },
    component: <UtilizedBudget consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/utilized-budget/new',
    },
    component: <UtilizedBudgetForm consubcon="contract" mode="new" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/utilized-budget/edit',
    },
    component: <UtilizedBudgetForm consubcon="contract" mode="edit" />,
  },
  //Budget Transfer
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/budget-transfer',
    },
    component: <BudgetTransferListing />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/budget-transfer/new',
    },
    component: <BudgetTransferForm consubcon="contract" mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/edit/:budgetTransferID',
    },
    component: <BudgetTransferForm consubcon="contract" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/resubmit/:budgetTransferID',
    },
    component: <BudgetTransferForm consubcon="contract" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/view/:budgetTransferID',
    },
    component: <BudgetTransferForm consubcon="contract" mode="view" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/approve-reject/:budgetTransferID',
    },
    component: (
      <BudgetTransferForm consubcon="contract" mode="approve-reject" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/budget-transfer/details',
    },
    component: <BudgetTransferDetails />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/insurance/add',
    },
    component: (
      <GuaranteeInsuranceForm
        type={'insurance'}
        consubcon={'contract'}
        mode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/insurance/:grnInsId/edit',
    },
    component: (
      <GuaranteeInsuranceForm
        type={'insurance'}
        consubcon={'contract'}
        mode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/guarantee',
    },
    component: <GuaranteeInsuranceListing />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/insurance',
    },
    component: (
      <GuaranteeInsuranceListing type={'insurance'} consubcon={'contract'} />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/guarantee/details',
    },
    component: (
      <GuaranteeInsuranceDetail type={'guarantee'} consubcon={'contract'} />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/insurance/detail/:grnInsId',
    },
    component: (
      <GuaranteeInsuranceDetail type={'insurance'} consubcon={'contract'} />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/add',
    },
    component: <VOForm consubcon="contract" mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/draft/:voId',
    },
    component: <VOForm consubcon="contract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/resubmit/:voId',
    },
    component: <VOForm consubcon="contract" mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/detail/:voId',
    },
    component: <VODetail consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo-project-health',
    },
    component: <VOProjectHealth />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/contract/submenu/:ID/site-progress',
  //   },
  //   component: <SiteProgressListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/contract/submenu/:ID/site-progress/add',
  //   },
  //   component: <SiteProgressForm mode="add" />,
  // },

  // {
  //   props: {
  //     exact: true,
  //     path: '/contract/submenu/:ID/site-progress/edit/:siteProgressID',
  //   },
  //   component: <SiteProgressForm mode="edit" />,
  // },
];

export default ContractRoutes;
